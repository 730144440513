<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('article.detail.title')"
      :sub-title="$t('article.detail.desc')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-2">
        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <b-card
            no-body
            class="wameed-card"
          >
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-reg-18 text-font-main">
                {{ $t('article.update.title') }}
              </h5>
            </b-card-header>
            <wameed-form
              refs=""
              @submitAction="submitarticle"
            >
              <template
                slot="form-content"
                slot-scope="{ invalid }"
              >
                <b-card-body
                  class="position-relative py-5 justify-content-between px-5"
                >
                  <!-- form -->
                  <div class=" py-4 article-detail">

                    <img
                      class="article-image"
                      :src="detail.image"
                      :alt="detail.title"
                    >

                    <div class="time">
                      <calendar-small-icon />
                      {{ moment(detail.created_at).format('ll') }}
                    </div>

                    <h2 class="title">
                      {{ detail.title }}
                    </h2>

                    <div
                      class="desc"
                      v-html="detail.content"
                    />

                  </div>
                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="d-flex">
                      <div class="px-4 py-3">
                        <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="$t('common.edit')"
                          :disabled="invalid"

                          variant="main"
                          @submitAction="edit"
                        />

                      </div>
                      <div class="px-4 py-3">
                        <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="$t('common.delete')"
                          :disabled="invalid"
                          variant="light-danger"
                          @submitAction="showModal=true"
                        />
                      </div>
                    </div>
                    <div class="px-4 py-3">
                      <wameed-btn
                        classes="  text-med-14 text-font-secondary rounded-10 "
                        :title="$t('common.cancel')"
                        type="button"
                        variant="gray"
                        @submitAction="cancelarticle"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </template>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>

      <warning-modal
        variant="danger"
        icon-name="trash-icon"
        :visible="showModal"
        :title="$t('article.modal.delete')"
        :sub-title="$t('article.modal.delete_desc')"
        :btn-title="$t('common.delete')"
        @close="showModal = false"
        @submitAction="deleteData()"
      />
    </section>
  </div>
</template>

<script>
import WameedForm from '@/components/WameedForm.vue';

import WameedBtn from '@/components/WameedBtn.vue';
import { mapActions, mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeader.vue';
import WarningModal from '@/components/WarningModal.vue';

export default {
  components: {
    WarningModal,
    WameedBtn,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      detail: 'getArticleDetails',
    }),
  },
  async mounted() {
    await this.loadArticleDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      deleteArticle: 'deleteArticle',
      loadArticleDetails: 'loadArticleDetails',
    }),

    cancelarticle() {
      this.$router.push({ name: 'articles' });
    },
    edit() {
      this.$router.push({
        name: 'updateArticle',
        params: { lang: this.$i18n.locale, id: this.$router.currentRoute.params.id },
      });
    },

    deleteData() {
      this.deleteArticle(
        this.$router.currentRoute.params.id,
      );
    },
  },
};
</script>
